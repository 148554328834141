import React from 'react';

const fuelcell = (
		<div> 
			Created in 2014 for the Canadian Open Data Experience.
			<br/><br/>
			Team Size : 2 (Daniyal Khan)
			<br/><br/>
			The goal was to create an app (Android Application) that utilized open data provided by the Canadian Government in 48 hours.
			<br/><br/>
			My teammate and I decided to create an application that could estimate the amount of gas a particular car model would use on a particular route.
			We stored the data in a local database (car model, size, mileage, etc.) and utilized Google Maps API to calculate the distance. 
			Using mileage and distance, we estimated expected gas usage. The application also doubled as a database for car data.
		</div>
	);

const orcs = (
		<div> 
			Created in 2012 for the Great Canadian Appathon.
			<br/><br/>
			Team Size : 2 (Daniyal Khan)
			<br/><br/>
			The goal was to create a game within 48 hours. We decided to create an Android Game using LibGDX.
			<br/><br/>
			My teammate and I decided to create a game based on the river escaping scene in The Hobbit. 
			The game was an infinite runner where your wizards were tumbling down the river in barrels while 
			orcs ran along the riverbed and tried to stop you from escaping. Survive as long as you can by dodging
			the rain of arrows while retaliating with your magic. 
			<br/><br/>
			Years later I recreated this game with updated graphics and slight tweaks to the logic. 
			<br/><br/>
			Our game was given the honor of being one of the top 15 entries for the Great Canadian Appathon.
			This invited us to the Canadian Open Data Experience. 
		</div>
	);

const reOrcs = (
		<div> 
			Created in 2015 just for fun.
			<br/><br/>
			Team Size : Solo
			<br/><br/>
			Based on the original game I created with my teammate in 2012, I added some new features and new 
			sprites to the original game. I wanted to try some of the problems that I did not encounter the first time around
			without having to think of what to design. The end product was much cleaner but missed a bit of the charm of the original.
		</div>
	);

const heartlock = (
		<div> 
			Created in 2014 for a Systems Design Workshop 1 course term project during my undergraduate degree. 
			<br/><br/>
			Team Size : 3 (Allan Cao, Davin Wong)
			<br/><br/>
			The goal was to create an authenticator based on a person's heartbeat.
			<br/><br/>
			My team created a authenticator based on the idea that by measuring the heartbeat and obtaining the ECG signal, we could uniquely identify users.
			We had used a heartbeat reader and used a random forest algorithm to determine the classification of the signal to a user. Through research we had learned that the 
			waveform could be unique between users. We were able to use our application to unqiuely identify between 2 of our team members. We had also implemented
			a user interface to live demo for passers by.
		</div>
	);

const blueGenie = (
		<div> 
			Created in 2014
			<br/><br/>
			Team Size : 2 (Daniyal Khan)
			<br/><br/>
			An unfinished side scrolling platforming RPG. 
			<br/><br/>
			Used Flash to create spritesheets of assets. The spritesheets were read using LibGDX and Flump. Implemented walking and attacking animations.
			<br/><br/>
			I was the head art director on the project, creating animations, backgrounds, characters, concept art, items, etc.
		</div>
	);

const hexaTile = (
		<div> 
			Created in 2015
			<br/><br/>
			Team Size : 2 (Daniyal Khan)
			<br/><br/>
			An unfinsihed strategy RPG with procedurally generated maps.
			<br/><br/>
			Using hexagons as the map tile shape giving a unique feel compared to squares.
			<br/><br/>
			Used Flash to create spritesheets of assets. The spritesheets were read using LibGDX and Flump. Implemented walking and attacking animations.
			<br/><br/>
			I was the head art director on the project, creating animations, backgrounds, characters, concept art, items, etc.
		</div>
	);

const nhltracker = (
		<div>
			Created in 2018
			<br/><br/>
			Team Size : Solo
			<br/><br/>
			Web Application exposing the NHL.com API to a React Web Application. I wanted to test out using ReactJS outside of
			my work environment and also wanted to see how NHL.com's API's were set up. I was able to get the stats outputted to a table
			however it is known that the NHL.com API is not consistent season to season so I didn't pursue the project any further.
			<br/><br/>
			With the application, a user was able to get the scores and stats for all players and teams at once. I used it personally since
			I am not a fan of the current NHL.com which hides lots of statistics. However now the application doesn't work since the 
			API is not not compatible anymore. I just use other third party aggregators now.
		</div>
	);

const nhldive = (
		<div>
			Started in 2019
			<br/><br/>
			Team Size : Solo
			<br/><br/>
			Ad-hoc deep dives into available data to create tangible understanding of what a good NHL player is
			<br/><br/>
			Somethings that I've started looking into include: the effect of rebounds on long term production, 
			types of goals players always concede goals to, etc.
		</div>
	);

class Descriptions {
	static getFuelCell() { return fuelcell; }
	static getHeartlock() { return heartlock; }
	static getBlueGenie() { return blueGenie; }
	static getHexaTile() { return hexaTile; }
	static getOrcs() { return orcs; }
	static getReOrcs() { return reOrcs; }
	static getNhlTracker() { return nhltracker; }
	static getNhlDive() { return nhldive; }
}

export default Descriptions;