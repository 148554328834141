import fuelcell from './imgs/project/fuelcellfull.png'
import heartlock from './imgs/project/heart.jpg'
import blueGenie from './imgs/project/backgroundgrassfull.png'
import hexaTile from './imgs/project/hexatilefull2.png'
import orcs from './imgs/project/oldorcsandbarrels.png'
import reOrcs from './imgs/project/neworcsandbarrels.png'
import hockey1 from './imgs/project/hockey1.jpg'
import hockey2 from './imgs/project/hockey2.jpg'

class Images {
	static getFuelCell() { return fuelcell; }
	static getHeartlock() { return heartlock; }
	static getBlueGenie() { return blueGenie; }
	static getHexaTile() { return hexaTile; }
	static getOrcs() { return orcs; }
	static getReOrcs() { return reOrcs; }
	static getHockey1() { return hockey1; }
	static getHockey2() { return hockey2; }
}

export default Images;