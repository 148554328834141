import React, { Component } from 'react';
// import logo from './logo.svg';
import './App.css';

import descriptions from './project_descriptions.jsx'
import images from './project_images.jsx'

// import resume_file from './imgs/RESUME 2019 v6.pdf';
// import { Document, Page, pdfjs } from "react-pdf";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// TODO Animation for buttons moving
// Add more links to words
// Try to create Circular Buttons
// Try to create bumpy circle images for fun
// Fix the beginning strange popping load
// Fix top bar when space is limited (keep it to one bar when bleed) (scroll waterfall)
// create nicer tech stack list
// Format work so suit website style
// recreate projects page (make it nicer)

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {page: "HOME"};
  }

  setPage(newPage) {
    this.setState({page: newPage})
  }

  link(link) {
    window.location.href = link
  }

  header(headerClass) {
    return (
      <div className={"section " + headerClass}>
        <span className={"name " + headerClass} > r y a n . n h i e u </span>
        { this.state.page !== "HOME" ? <button className={headerClass + " button btn"} type="button" onClick={() => this.setPage("HOME")}>h o m e</button> : null}
        <button className={headerClass + " button btn"} type="button" onClick={() => this.setPage("ABOUT")}>a b o u t</button>
        <button className={headerClass + " button btn"} type="button" onClick={() => this.setPage("WORK")}>w o r k</button>
        <button className={headerClass + " button btn"} type="button" onClick={() => this.setPage("TECH")}>t e c h</button>
        <button className={headerClass + " button btn"} type="button" onClick={() => this.link('https://www.linkedin.com/in/ryannhieu')}>l i n k e d i n</button>
        <button className={headerClass + " button btn"} type="button" onClick={() => this.link('https://github.com/l2y')}>g i t h u b</button>
        {/*<button className={headerClass + " button btn"} type="button" onClick={() => this.setPage("PROJECTS")}>p r o j e c t s</button>*/}
      </div>
    )
  }

  contents() {
    if (this.state.page === "ABOUT") {
      return (<About/>)
    } else if (this.state.page === "RESUME") {
      return (<Resume/>)
    } else if (this.state.page === "WORK") {
      return (<Work/>)
    } else if (this.state.page === "TECH") {
      return (<Tech/>)
    } else if (this.state.page === "PROJECTS") {
      return (<Projects navigate={(input) => this.setPage(input)}/>)
    }
  }

  render() {
    return (
      <div className="App">
        { this.state.page !== "HOME" ? this.header("header") : null}
        <header className="App-header">
          { this.state.page === "HOME" ? this.header("main"): this.contents() }
        </header>
      </div>
    );
  }
}

class About extends Component {

  render() {
    return (
      <div className="main about">
        Hello, my name is <b>Ryan</b>!
        <br/><br/>
        I'm from <b>Toronto</b> <b>Canada</b>, on my journey through engineering. I graduated from the <b>University of Waterloo</b> from <b>Systems Design Engineering</b>. Since then I've worked for <b>Amazon</b> as an SDE and now I'm at <b>The Athletic</b> as a Senior Data Engineer. I now reside and work in Toronto. I love learning new things and solving unique problems. I've worked professionally on Frontend, Backend, Infrastructure and Machine Learning projects.
        <br/><br/>
        Some of my interests outside of work include my dog, animation, drawing, hockey, music, guitar, video games and tv.
        <br/><br/>
      </div>
    )
  }
}

class Resume extends Component {

  download() {
    // window.location.href = resume_file;
  }

  render() {
    return(
      <div>
        {/*<Document className="pdfPage" file={resume_file} onLoadError={console.error}>
          <Page className="pdfPage" pageNumber={1} />
        </Document>
        <button className={"download btn btn-outline-success"} type="button" onClick={() => this.download()}>Download</button>*/}
      </div>
    )
  }
}

class Work extends Component {

  render() {
    return (
      <div className="main about">
        <b>Senior Data Engineer</b>
        <br/><br/>
        <b>The Athletic</b>
        <br/><br/>
        <ul>
          <li>Built a recommendation service for feeds, curating podcasts, articles and other content</li>
          <li>Maintained and built API with 30 requests/s on AWS Lambda</li>
          <li>Normalized 5+ data sources to create a single order of content</li>
          <li>Recalculated 5 million user feeds hourly using EMR+Spark and stored in Redis</li>
          <li>Created kafka consumers and airflow DAGs to update content and features as they change</li>
          <li>Exposed recommendations using GraphQL, blending multiple content types ~700ms for ~1.3 million requests/day</li>
          <li>Led projects with 2 engineers and myself, collaborating with design and product</li>
          <li>Worked with mobile and web clients to include all required information (titles, images, etc)  in response</li>
          <li>Created API to aid app and web clients in storing analytic events in Kafka </li>
          <li>Simplifying migration between JSON and Avro messages through Schema Registry</li>
          <li>Included batch ingestion, inherent retries, DLQ and verbose error messaging</li>
          <li>Decreased data pipeline downtime from 99% to 99.9% by optimizing database usage and improving alerting</li>
          <li>Owned and operated Kubernetes, Kafka Brokers, Airflow, AWS Redshift, Redis, etc. deployments</li>
          <li>Created article search backend using Elasticsearch - decreased average response time from ~300ms to ~30ms</li>
          <li>Consolidated logging into ELK cluster to improve logging and debugging requests from minutes to seconds</li>
          <li>Implemented metrics and alerts using Prometheus, Grafana, Datadog and PagerDuty</li>
          <li>Built ETLs for internal tools and third party vendors (Facebook, Twitter, Kochava, etc.) using Airflow and EMR</li>
          <li>Improved and maintained data infrastructure to power internal analytics for 500+ editorial team</li>
          <li>Mentored teammates across multiple organizations (Analytics, Data, Engineering, etc)</li>
        </ul>
        <br/><br/>
        <b>Software Development Engineer I & II</b>
        <br/><br/>
        <b>Amazon</b>
        <br/><br/>
        <ul>
          <li>Launched order confidence scoring service</li>
          <li>Built real-time scoring of order confidences using a machine learning model for 10 million orders a day</li>
          <li>Launched automated batch prediction framework using EMR to improve 5% of delivery estimates</li>
          <li>Created feature gather pipeline used for training and scoring using Spark, DynamoDB, Lambda</li>
          <li>Evaluated features (shipping time, docking, etc.) for our model by consulting external teams</li>
          <li>Launched web-app for surfacing and manipulating item availability data on amazon.com</li>
          <li>Increased usage by 400%</li>
          <li>Replaced old JavaScript with ReactJS, simplifying testing and deployment</li>
          <li>Mentored intern to deliver impactful projects within a four month timeframe</li>
          <li>Provided code and design reviews, while also onboarding them to new technologies</li>
          <li>Led initiative to standardize documentation of team owned services</li>
          <li>Improved knowledge transfer between service owners </li>
          <li>Decreased ramp-up for new teammates and hires</li>
        </ul>
        <br/><br/>
      </div>
    )
  }
}

class Tech extends Component {

  render() {
    return (
      <div className="main about">
        <ul>
          <li>Python</li>
          <li>SQL</li>
          <li>JavaScript</li>
          <li>GraphQL</li>
          <li>Scala-Spark</li>
          <li>Java</li>
          <li>ReactJS</li>
          <li>AWS</li>
          <li>Git</li>
          <li>Markdown</li>
        </ul>
      </div>
    )
  }
}

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // navigate : this.props.navigate
      navigate : () => {}
    }
  } 

  render() {
    return (
      <div>
        <div className="project section">
          <ProjectEntry name="Fuel Cell" desc={descriptions.getFuelCell()} img={images.getFuelCell()} navigate={this.state.navigate}/>
          <ProjectEntry name="Orcs and Barrels" desc={descriptions.getOrcs()} img={images.getOrcs()} navigate={this.state.navigate}/>
          <ProjectEntry name="Orcs and Barrels Remake" desc={descriptions.getReOrcs()} img={images.getReOrcs()} navigate={this.state.navigate}/>
        </div>
        <div className="project section">
          <ProjectEntry name="NHL Stat Tracker" desc={descriptions.getNhlTracker()} img={images.getHockey1()} navigate={this.state.navigate}/>
          <ProjectEntry name="NHL Data Investigations" desc={descriptions.getNhlDive()} img={images.getHockey2()} navigate={this.state.navigate}/>
          <ProjectEntry name="HeartLock" desc={descriptions.getHeartlock()} img={images.getHeartlock()} navigate={this.state.navigate}/>
        </div>
        <div className="project section">
          <ProjectEntry name="Blue Genie" desc={descriptions.getBlueGenie()} img={images.getBlueGenie()} navigate={this.state.navigate}/>
          <ProjectEntry name="HexaTile" desc={descriptions.getHexaTile()} img={images.getHexaTile()} navigate={this.state.navigate}/>
        </div>
      </div>
    )
  }
}

class ProjectEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      desc: this.props.desc,
      img: this.props.img,
      navigate: this.props.navigate
    };
  }

  render() {
    var style = {
      'background-image': 'url(' + this.props.img + ')'
    }

    return (
      <div style={style} className="project entry outer">
        <div className="project entry inner" onClick={() => this.state.navigate(this.state.name)}>
          <div className="project desc container">
            <div className="project name"> {this.state.name} </div>
            <div className="project desc"> {this.state.desc} </div>
          </div>
        </div>
        <span className="project name">{this.state.name}</span>
      </div>
    );
  }
}

export default App;